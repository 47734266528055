import moment from 'moment';
import { IPostContentData } from 'src/api';
import { Query } from 'src/interfaces';

export const formatImageUrl = (image = '') => {
    if (image.includes('http')) {
        return image;
    }
    const imageWithoutSlash = image[0] === '/' ? image.slice(1) : image;
    const strapiUrl = process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337';
    const strapiUrlFormatted =
        strapiUrl[strapiUrl.length - 1] === '/' ? strapiUrl : strapiUrl + '/';
    return strapiUrlFormatted + imageWithoutSlash;
};

export const formateDate = (date: string, local = 'fr') => {
    return moment(date).locale(local);
};

export function toQuery(queryObj: Query) {
    if (!queryObj || !Object.keys(queryObj).length) return "";
    const queries: string[] = [];
    Object.keys(queryObj).forEach((key) => {
        if (queryObj[key]) {
            queries.push(`${key}=${queryObj[key]}`);
        }
    });
    return `?${queries.join("&")}`;
}


export function lowercase(str: string): string {
    return str.toLowerCase()
}

export function slugify(str: string): string {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
}

export const generateContentApiPayload = (data: IPostContentData) => {
    const result = {
        ...data,
        mainImage: data.mainImage.id,
        slug: slugify(data.title),
        video: data.videos?.[0]?.id,
        audio: data.audios?.[0]?.id,
    };
    return result;
}

export function formatLocaleDateString(dateString: string | Date) {
    const date = new Date(dateString);
    const options: any = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    };

    const formattedDate =
        date.toLocaleDateString("fr-FR") + " à " + date.toLocaleTimeString("fr-FR");

    return formattedDate;
}

export function createSortString(sortString: string): string {
    const [path, order] = sortString.split(':');
    const fields = path.split('.');

    let result = 'sort[0]';
    fields.forEach(field => {
        result += `[${field}]`;
    });

    result += `=${order}`;
    return result;
}
